/** @jsx jsx */
import { jsx } from "theme-ui";
import { setUser, setUserToken } from "gatsby-plugin-hfn-profile/state/actions";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { fetchSrcmProfileMe } from "sites-common/service-wrappers/profile-api";
import { useLocation } from "@reach/router";
import { fget } from "gatsby-plugin-hfn-profile/components/Login";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";

const OneAuthPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const apiCallCount = useRef(0);
  const { srcmProfile } = useAuth();

  useEffect(() => {
    if (srcmProfile)
      window.location.href = `${window.location.origin}${localStorage.getItem(
        "LandingPage"
      )}`;
  }, [srcmProfile]);

  const handleAuth = useCallback((status) => {
    document.querySelector("hfn-oneauth")?.handleProfileAuthentication(status);
  }, []);

  useEffect(() => {
    const initializeAuthProfile = async () => {
      await import("hfn-oneauth/main");

      const authProfileElement = document.querySelector("hfn-oneauth");
      if (authProfileElement) {
        authProfileElement.loginCallback = async (response) => {
          try {
            const user = response?.data;
            if (!user?.access_token) return false;
            apiCallCount.current += 1;
            if (apiCallCount.current < 4) {
              const newsrcmprofile = await fetchSrcmProfileMe(
                user?.access_token
              );
              if (newsrcmprofile) {
                dispatch(
                  setUser(
                    fget(newsrcmprofile, newsrcmprofile?.user_firebase_uid)
                  )
                );
                const d = new Date();
                d.setHours(d.getHours() + 8);
                const ts = d.valueOf();
                dispatch(
                  setUserToken({
                    token: user?.access_token,
                    fbName: newsrcmprofile.name,
                    user: newsrcmprofile.email,
                    uid: newsrcmprofile.user_firebase_uid,
                    ts,
                    type: "fb",
                  })
                );
                handleAuth(true);
                window.location.href = `${
                  window.location.origin
                }${localStorage.getItem("LandingPage")}`;
                // navigate(localStorage.getItem("LandingPage"), {
                //   state: { from: location.pathname },
                // }).then(() => {
                //   window.location.reload();
                // });
              } else {
                handleAuth(false);
              }
            }
          } catch (error) {
            handleAuth(false);
          }
          return true;
        };
      }
    };

    initializeAuthProfile();
  }, [dispatch, handleAuth, location.pathname]);
  return <div />;
};

export default OneAuthPage;
